// extracted by mini-css-extract-plugin
export var flatIphone = "Ipad-module--flatIphone--e9922";
export var flatIphoneWrapper = "Ipad-module--flatIphoneWrapper--a8b7b";
export var ipad = "Ipad-module--ipad--51a4a";
export var moduleWrapper = "Ipad-module--moduleWrapper--8e37d";
export var reversedIpad = "Ipad-module--reversedIpad--ed73c";
export var rowContainer = "Ipad-module--rowContainer--0b64b";
export var text = "Ipad-module--text--30943";
export var textContainer = "Ipad-module--textContainer--4a36a";
export var textHeader = "Ipad-module--textHeader--ffb8f";
export var videoWrapper = "Ipad-module--videoWrapper--6a369";