import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import PropTypes from "prop-types";
import * as IpadStyling from "./Ipad.module.scss";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import { graphql } from "gatsby";
import Video from "../../Video/Video";

const Ipad = ({ block }) => {
  const items = block.items;
  const isToLeft = block.reverseOrder;

  return (
    <Section className={`ipadModule ${IpadStyling.moduleWrapper}`}>
      {items.map((item, index) => {
        return (
          <div
            key={item.originalId}
            className={
              isToLeft
                ? `row r-middle ${IpadStyling.rowContainer} ${IpadStyling.reversedIpad}`
                : `row r-middle ${IpadStyling.rowContainer}`
            }
            style={
              isToLeft
                ? { flexDirection: index % 2 === 0 ? "row-reverse" : "row" }
                : { flexDirection: index % 2 === 0 ? "row" : "row-reverse" }
            }
          >
            {item.desktopMedia.isImage ? (
              <GatsbyImage
                image={item.desktopMedia.gatsbyImageData}
                className={`hide-to-m ${IpadStyling.ipad}`}
                alt={item.desktopMedia.alt}
              />
            ) : (
              <Video
                src={item.desktopMedia.url}
                className={`hide-to-m ${IpadStyling.ipad}`}
                noControl
              />
            )}
            <div
              className={`col c-12 mb-7 hide-from-m ${IpadStyling.flatIphoneWrapper}`}
            >
              {item.mobileMedia.isImage ? (
                <GatsbyImage
                  image={item.mobileMedia.gatsbyImageData}
                  className={IpadStyling.flatIphone}
                  alt={item.mobileMedia.alt}
                />
              ) : (
                <div className={IpadStyling.videoWrapper}>
                  <Video
                    src={item.mobileMedia.url}
                    className={IpadStyling.flatIphone}
                    noControl
                  />
                </div>
              )}
            </div>
            <div
              className={`col c-12 m-4 l-5 mb-7 mono-container ${IpadStyling.textContainer}`}
            >
              <StructuredTextRenderer
                data={item.content}
                isParagraphClassName={`opa75 ${IpadStyling.text}`}
                isHeadingClassName={`mb-5 ${IpadStyling.textHeader}`}
              />
            </div>
          </div>
        );
      })}
    </Section>
  );
};

export default Ipad;

Ipad.propTypes = {
  block: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        originalId: PropTypes.string.isRequired,
        content: PropTypes.object.isRequired,
        desktopMedia: PropTypes.shape({
          isImage: PropTypes.bool.isRequired,
          alt: PropTypes.string,
          gatsbyImageData: PropTypes.object,
          format: PropTypes.string,
          url: PropTypes.string,
        }).isRequired,
        mobileMedia: PropTypes.shape({
          isImage: PropTypes.bool.isRequired,
          alt: PropTypes.string,
          gatsbyImageData: PropTypes.object,
          format: PropTypes.string,
          url: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
    reverseOrder: PropTypes.bool,
  }),
};

export const query = graphql`
  fragment Ipad on DatoCmsIpad {
    model {
      apiKey
    }
    id: originalId
    reverseOrder
    items {
      originalId
      ... on DatoCmsIpaditem {
        content {
          value
        }
        desktopMedia {
          isImage
          gatsbyImageData
          alt
          format
          url
        }
        mobileMedia {
          isImage
          gatsbyImageData
          alt
          format
          url
        }
      }
    }
  }
`;
