import React from "react";
import PropTypes from "prop-types";

const Video = ({className, src, controlPlayer}) => {

  if (controlPlayer) {
    return (
      <video style={{maxWidth: '100%'}} className={className} controls controlsList="nodownload">
        <source src={src} type="video/mp4" />
      </video>
    )
  } else {
    return (
      <video style={{maxWidth: '100%'}} className={className} autoPlay muted loop playsInline>
        <source src={src} type="video/mp4" />
      </video>
    )
  }
};

export default Video;

Video.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  type: PropTypes.bool
};